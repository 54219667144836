/** @jsx jsx */
import {jsx} from '@emotion/react';
import {lowSpeedContainer, previewContainer} from './style';

import {Button} from 'primereact/button';
import {useLocale} from '../../hooks';

interface LowSpeedPreviewProps {
  onRestore?: () => void;
  onStop?: () => void;
}

export const LowSpeedPreview = (props: LowSpeedPreviewProps) => {
  const {onRestore, onStop} = props;
  const locale = useLocale();
  return (
    <div css={[previewContainer, lowSpeedContainer]}>
      <div className="p-d-flex buttons-wrapper">
        <Button
          data-role="button-play"
          onClick={onRestore}
          icon={'mdi mdi-18px mdi-restore'}
          className={'p-button-text p-button-plain p-button-sm'}
          label={locale.messages.restart}
        />
        <Button
          onClick={onStop}
          icon={'mdi mdi-18px mdi-stop'}
          className={'p-button-text p-button-plain p-button-sm'}
          label={locale.messages.stop}
        />
      </div>
      <div className="error-description">{locale.messages.onStalled}</div>
    </div>
  );
};
