import React, { createContext, ReactNode, useContext, useReducer } from "react";

const sensitivityConfig = {
  value: 0.05,
  min: 0.05,
  max: 1,
  step: 0.05
}

export type SensitivityProviderProps = {
  value?: typeof sensitivityConfig
  children: ReactNode
}

const SensitivityContext = createContext({
  sensitivitySettings: sensitivityConfig,
  setSensitivitySettings: (value: Partial<typeof sensitivityConfig>) => {}
})

export const useSensitivity = () => useContext(SensitivityContext)

export const SensitivityProvider = ({ value = sensitivityConfig, children }: SensitivityProviderProps) => {
  const [sensitivitySettings, setSensitivitySettings] = useReducer(
    (sensitivitySettings: typeof value, payload: Partial<typeof value>) => ({ ...sensitivitySettings, ...payload }),
    value
  )
  return (
    <SensitivityContext.Provider value={{ sensitivitySettings, setSensitivitySettings }}>
      {children}
    </SensitivityContext.Provider>
  )
}
