import {createCamerasConnection, listEntities} from '@netvision/lib-api-gateway';

const countAnalytics = ({q, type = 'AssignmentGroup'}: {q?: string; type?: string}) => {
  return listEntities(createCamerasConnection(), {
    type,
    q,
    limit: 1,
    count: true
  })
    .then(({count}) => count)
    .catch((e) => {
      console.error(e);
      return -1;
    });
};

export const countActiveAnalyticsByCamera = (cameraId: string) => {
  return countAnalytics({
    q: `entityType=='Camera';entityId=='${encodeURIComponent(cameraId)}';status=='Started'`,
    type: 'Assignment'
  });
};

export const countAnalyticsByPreset = (presetId: string) => {
  return countAnalytics({
    q: `parameters.presetId=='${encodeURIComponent(presetId)}'`
  });
};
