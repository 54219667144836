import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  MutableRefObject
} from 'react';
import {useApiRepository} from './useApiRepository';
import {TUnionRepo} from '@netvision/lib-api-repo';

interface StreamProviderProps {
  defaultStreamType?: string;
  defaultStreamId?: string;
  cameraId: string;
  eventNode?: HTMLElement;
  refreshAfterUnlockTime: number;
  maxStreamRefreshAttempts: number;
  streamSetter?: (setStreamByType: (streamType: string) => void) => void;
}

interface IStream {
  id: string;
  type: 'Stream';
  title: string;
  cameraId: string;
  wsStreamUrl: string;
  snapshotStreamUrl: string;
  streamType?: string;
  orderIndex: number;
}

export enum StreamStatuses {
  error,
  success
}

type LazyAttrsStream = Pick<
  IStream,
  'id' | 'snapshotStreamUrl' | 'wsStreamUrl' | 'title' | 'streamType' | 'orderIndex'
>;
export type Stream = LazyAttrsStream & {status: StreamStatuses};

type StreamGetterContext = {
  lastActiveStreamChangedByUser: MutableRefObject<LazyAttrsStream | undefined>;
  activeStream?: Stream | null;
  streams?: Stream[];
  fetchStreams: () => void;
};

const StreamGetterContext = createContext<StreamGetterContext>(null!);

const SetterStreamContext = createContext<{
  setStreamById: (id: string, fireEvent?: boolean) => void;
  setStreamByType: (type?: string) => void;
  updateStreamStatus: (id: string, status: StreamStatuses) => void;
}>(null!);

const getAllStreamsByCameraId = async (api: TUnionRepo, cameraId: string) => {
  const hasGlobalBatch = 'getEntitiesWithGlobalBatch' in api;
  const hasCubeEntities = 'cubeGetEntities' in api;

  if (!hasCubeEntities || !api?.cubeGetEntities || !hasGlobalBatch) {
    console.error('Can not implements method getEntitiesWithGlobalBatch or cubeGetEntities');
    return [];
  }

  try {
    const {results} = await api?.cubeGetEntities<{id: string}>({
      dimensions: ['Stream.id'],
      filters: [
        {
          member: 'Stream.cameraId',
          operator: 'equals',
          values: [cameraId]
        }
      ]
    });

    const promises = results.map(({id}) =>
      api
        .getEntitiesWithGlobalBatch(
          {type: 'Stream', id},
          ['wsStreamUrl', 'snapshotStreamUrl', 'streamType', 'title', 'orderIndex'],
          'player-live'
        )
        .catch((e) => {
          console.error(e);
          return Promise.reject();
        })
    );

    return (await Promise.all(promises)) as LazyAttrsStream[];
  } catch (e) {
    console.error(e);
    return [];
  }
};

const prepareStreams = (streams: LazyAttrsStream[], status: StreamStatuses): Stream[] =>
  streams
    .filter((s) => s.wsStreamUrl)
    .map((stream) => ({...stream, status}))
    .sort((a, b) => a.orderIndex - b.orderIndex);

const eventCreator = (details: Record<string, any>) =>
  new CustomEvent('ChangeStream', {
    bubbles: true,
    detail: details
  });

export const StreamProvider: FC<StreamProviderProps> = ({
  children,
  cameraId,
  defaultStreamType = 'Live',
  defaultStreamId,
  eventNode,
  streamSetter,
  maxStreamRefreshAttempts,
  refreshAfterUnlockTime
}) => {
  const lastActiveStreamChangedByUser = useRef<Stream>();
  const [streams, setStreams] = useState<Stream[]>();
  const [activeStream, setActiveStream] = useState<Stream | null>();
  const {api} = useApiRepository();
  const fetchRetryCount = useRef(0);

  // disable because single-spa remove node from props after re-render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatchNode = useMemo(() => eventNode, []);

  const getDefaultStream = useCallback(
    (streams: Stream[]) => {
      const defaultStreamByType = streams.find(({streamType}) => defaultStreamType === streamType);
      const defaultStreamById = streams.find(({id}) => defaultStreamId === id);
      return defaultStreamById || defaultStreamByType || streams[0];
    },
    [defaultStreamId, defaultStreamType]
  );

  const updateStreamStatus = useCallback(
    (streamId: string, status: StreamStatuses) => {
      let newActiveStream: Stream | undefined;
      const newStreams = streams?.map((stream) => {
        if (stream.id === streamId) {
          newActiveStream = {...stream, status};
          return newActiveStream;
        }
        return stream;
      });

      if (!newStreams || !newActiveStream) return;
      setStreams(newStreams);
      setActiveStream(newActiveStream);
    },
    [streams]
  );

  const setStreamById = useCallback(
    (id: string, fireEvent?: boolean) => {
      const stream = streams?.find(({id: streamId}) => streamId === id);

      if (!stream) {
        console.error('Can not find stream with id:', id);
        return;
      }

      setActiveStream(stream);
      fireEvent && dispatchNode?.dispatchEvent(eventCreator(stream));
      lastActiveStreamChangedByUser.current = stream;
    },
    [dispatchNode, streams]
  );

  const setStreamByType = useCallback(
    (type?: string) => {
      const actualType = type || getDefaultStream(streams || [])?.streamType;
      const stream = streams?.find(({streamType}) => streamType === actualType);

      if (!stream) {
        console.error('Can not find stream with type:', type);
        return;
      }

      setActiveStream(stream);
    },
    [getDefaultStream, streams]
  );

  useEffect(() => {
    if (!streams || !streamSetter) return;
    streamSetter(setStreamByType);
  }, [setStreamByType, streamSetter, streams]);

  const fetchStreams = useCallback(() => {
    getAllStreamsByCameraId(api, cameraId)
      .then((res) => {
        const streams = prepareStreams(res, StreamStatuses.success);

        if (!streams.length && fetchRetryCount.current < maxStreamRefreshAttempts) {
          setTimeout(fetchStreams, refreshAfterUnlockTime);
          fetchRetryCount.current++;
          return;
        }

        if (!streams.length) {
          setStreams(undefined);
          setActiveStream(null);
        }

        const activeStream = getDefaultStream(streams);

        setStreams(streams);
        setActiveStream(activeStream || null);
      })
      .catch(() => {
        setStreams(undefined);
        setActiveStream(null);
      });
  }, [api, cameraId, getDefaultStream]);

  useEffect(() => {
    fetchStreams();
  }, [fetchStreams]);

  const getters = useMemo(
    () => ({activeStream, streams, lastActiveStreamChangedByUser, fetchStreams}),
    [activeStream, lastActiveStreamChangedByUser, streams, fetchStreams]
  );
  const setters = useMemo(
    () => ({setStreamById, setStreamByType, updateStreamStatus}),
    [setStreamById, setStreamByType, updateStreamStatus]
  );

  return (
    <SetterStreamContext.Provider value={setters}>
      <StreamGetterContext.Provider value={getters}>{children}</StreamGetterContext.Provider>
    </SetterStreamContext.Provider>
  );
};

export const useGetActiveStream = () => useContext(StreamGetterContext);
export const useSetActiveStream = () => useContext(SetterStreamContext);
