import {Instance, types} from 'mobx-state-tree';

export type IChangeSource = 'api' | 'user';
export type IStore = Instance<typeof StoreModel>;
export const createStore = () => StoreModel.create();
const StoreModel = types
  .model({
    isPlaying: types.optional(types.boolean, false),
    volume: types.optional(types.number, 0),
    isMuted: types.optional(types.boolean, true),
    isFullscreen: types.optional(types.boolean, false),
    isWaiting: types.optional(types.boolean, false),
    naturalSize: types.optional(types.model({width: types.number, height: types.number}), {width: 0, height: 0}),
    isPtzSupported: types.optional(types.maybeNull(types.boolean), null),
    isPtzVisible: types.optional(types.boolean, false),
    isDisablePtz: types.optional(types.boolean, false),
    isUnavailable: types.optional(types.boolean, false),
    isNoPreview: types.optional(types.boolean, false),

    isBlockedStream: types.optional(types.boolean, false),
    isBlockedControls: types.optional(types.boolean, false),
  })
  .actions((self) => {
    return {
      setIsBlockedStream(value: boolean) {
        self.isBlockedStream = value;
      },
      setIsBlockedControls(value: boolean) {
        self.isBlockedControls = value
      },
      setIsNoPreview(value: boolean) {
        self.isNoPreview = value;
      },
      setIsPlaying(value: boolean, source: IChangeSource) {
        self.isPlaying = value;
      },
      setVolume(value: number, source: IChangeSource) {
        if (0 <= value && value <= 1) {
          self.volume = value;
        }
      },
      setIsMuted(value: boolean, source: IChangeSource) {
        self.isMuted = value;
      },
      setIsFullscreen(value: boolean, source: IChangeSource) {
        self.isFullscreen = value;
      },
      setIsWaiting(value: boolean) {
        self.isWaiting = value;
      },
      setNaturalSize(width: number, height: number, source: IChangeSource) {
        if (width >= 0 && height >= 0) {
          self.naturalSize = {width, height};
        }
      },
      setIsPtzVisible(value: boolean) {
        self.isPtzVisible = value;
      },
      setIsPtzSupported(value: boolean) {
        self.isPtzSupported = value;
        self.isPtzVisible = false;
      },
      setIsUnavailable(value: boolean) {
        self.isUnavailable = value;
      },
      setIsDisablePtz(value: boolean) {
        self.isDisablePtz = value;
      }
    };
  });
