export function fetchPoster(headers: HeadersInit, posterUrl: string, signal: AbortSignal): Promise<Blob | null> {
  if (!posterUrl) return Promise.resolve(null);
  return fetch(decodeURIComponent(posterUrl), {
    signal,
    headers
  })
    .then((res) => {
      if (
        res.status === 200 &&
        ['image/jpeg', 'video/mp4', 'image/png'].some((_type) => _type === res.headers.get('Content-Type'))
      ) {
        return res.blob().catch((e) => {
          console.error('Could not parse preview blob');
          return null;
        });
      } else {
        console.error('Preview cannot be fetched');
        return null;
      }
    })
    .catch((e) => {
      return null;
    });
}
