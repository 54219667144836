export const defaultLocale = {
  messages: {
    onStalled: 'Из-за низкой скорости соединения плеер перешел в режим показа превью',
    restart: 'Возобновить',
    stop: 'Остановить',
    error: 'Ошибка',
    success: 'Успех',
    presetUpdated: 'Скриншот обновлен',
    presetError: 'Не удалось обновить скриншот',
    moveToDefaultPreset: 'Камера возвращена в позицию по умолчанию!'
  },
  controls: {
    ptz: 'Управление',
    refresh: 'Обновить',
    play: 'Старт',
    pause: 'Пауза',
    volume: 'Громкость',
    enterFullscreen: 'Полноэкранный режим',
    exitFullscreen: 'Покинуть полноэкранный режим',
    updatePresetImage: 'Обновить позицию камеры',
    createPresetImage: 'Создать позицию камеры',
    saveScreenshot: 'Сохранить кадр'
  },
  ptzControls: {
    zoom: 'Масштаб',
    defaultPresetTitle: 'По умолчанию',
    ptzControl: 'Управление',
    unlock: {
      header: 'Разблокировать?',
      message:
        'Управление камерой заблокировано, так как перемещение камеры может прервать запущенные в данный момент аналитики ({{}}). Все равно продолжить? ',
      yes: 'Да',
      no: 'Нет'
    },
    blocked: {
      header: 'Камера заблокирована',
      message: 'Доступ к управлению камерой заблокирован.',
    },
    connectionError: {
      header: 'Ошибка соединения!',
      message: 'Ошибка получения PTZ статуса камеры.',
      close: 'Закрыть'
    },
    presets: {
      header: 'Позиции камеры',
      add: 'Добавить',
      create: 'Сохранить позицию',
      rename: 'Переименовать',
      goToPreset: 'Перевести камеру в позицию',
      refreshPresetImage: 'Обновить скриншот позиции',
      cantRefreshPresetImage: 'Для обновления скриншота переведите камеру в выбранную позицию',
      putPositionToActual: 'Необходимо установить камеру в позицию',
      presetPlaceholder: 'Не выбрано',
      createDetail: 'Текущая позиция камеры будет сохранена',
      createAction: 'Запомнить',
      refresh: 'Обновить позицию',
      refreshDetail: 'Предыдущая позиция будет перезаписана',
      refreshAction: 'Обновить',
      title: 'Название',
      yes: 'Да',
      no: 'Нет',
      ok: 'Ок',
      cancel: 'Отмена',
      delete: 'Удалить',
      deleteDetail: 'Удалить предустановку?',
      loadingDeleteDetail: 'Проверка аналитик...',
      cannotDeleteDetail:
        'Данная предустановка не может быть удалена, так как она связана с некоторыми аналитиками ({{}}).',
      unexpectedError: 'Ошибка при выполнении запроса',
      accessDenied: 'Отказано',
      accessDeniedDetail: 'Требуемые права отсутствуют.'
    },
    close: 'закрыть',
    scale: 'Масштаб',
    sensitivity: {
      label: 'Чувствительность'
    }
  },
  player: {
    restart: 'Сначала',
    rewind: 'Назад на {seektime} секунд',
    play: 'Начать проигрывание',
    pause: 'Пауза',
    fastForward: 'Вперед на {seektime} секунд',
    seek: 'Поиск',
    seekLabel: '{currentTime} от {duration}',
    played: 'Проиграно',
    buffered: 'Буффер',
    currentTime: 'Текущее время',
    duration: 'Длительность',
    volume: 'Громкость',
    mute: 'Отключить звук',
    unmute: 'Включить звук',
    enableCaptions: 'Включить субтитры',
    disableCaptions: 'Выключить субтитры',
    download: 'Загрузить',
    enterFullscreen: 'Полноэкранный режим',
    exitFullscreen: 'Покинуть полноэкранный режим',
    frameTitle: 'Проигрыватель для {title}',
    captions: 'Субтитры',
    settings: 'Настройки',
    pip: 'PIP',
    menuBack: 'Назад',
    speed: 'Скорость',
    normal: 'Нормальное',
    quality: 'Качество',
    loop: 'Зациклить',
    start: 'Начало',
    end: 'Конец',
    all: 'Все',
    reset: 'Сбросить',
    disabled: 'Отключено',
    enabled: 'Включено',
    advertisement: 'Реклама',
    qualityBadge: {
      2160: '4K',
      1440: 'HD',
      1080: 'HD',
      720: 'HD',
      576: 'SD',
      480: 'SD'
    }
  }
};
