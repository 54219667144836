import {MutableRefObject, useEffect, useRef} from 'react';
import {reaction} from 'mobx';

import {MountHooks, OverlayParams} from '../IWidgetProps';

import {IStore} from '../IStore';
import {createDisposers} from '../utils/disposers';

export const useMountOverlay = (
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  store: IStore | null,
  overlayMountHooks?: MountHooks<OverlayParams>
) => {
  const externalOverlayRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const video = videoRef.current;
    const externalOverlay = externalOverlayRef.current;
    if (store === null || video === null || externalOverlay === null) {
      return undefined;
    }

    const d = createDisposers();

    if (typeof overlayMountHooks === 'object') {
      d.add(
        reaction(
          () => [store.naturalSize.width, store.naturalSize.height],
          ([width, height], r) => {
            if (height > 0 && width > 0) {
              overlayMountHooks.mount({
                overlayContainer: externalOverlay,
                overlayBase: video,
                naturalWidth: width,
                naturalHeight: height
              });
              d.add(overlayMountHooks.unmount);
              r.dispose();
            }
          }
        )
      );
    }

    return d.flush;
  }, [videoRef, store, overlayMountHooks]);
  return externalOverlayRef;
};
