/** @jsx jsx */
import {jsx} from '@emotion/react';
import {makeButton} from './makeButton';

export const StreamButton = makeButton((props) => {
  return (
    <svg width="32" height="32" viewBox="0 -4 18 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m18 8-3-1V6l1-2V3l-1-1h-1l-2 1h-1l-1-3H8L7 3H6L4 2H3L2 3v1l1 2v1L0 8v2l3 1v1l-1 2v1l1 1h1l2-1h1l1 3h2l1-3h1l2 1h1l1-1v-1l-1-2v-1l3-1V8Zm-9 4a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"
        fill="#FFF"
      />
    </svg>
  );
});
