import React, {createContext, FC} from 'react';
import {IWidgetProps} from '../IWidgetProps';
import {WidgetReceivedProps} from '@netvision/lib-widget-renderer';

export const Context = createContext<
  IWidgetProps & {mountChildren: WidgetReceivedProps<IWidgetProps, ''>['mountChildren']}
>(null!);

export const Provider: FC<IWidgetProps & {mountChildren: WidgetReceivedProps<IWidgetProps, ''>['mountChildren']}> = (
  props
) => <Context.Provider value={props}>{props.children}</Context.Provider>;
