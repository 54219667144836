import React, {useEffect, useRef} from 'react';
import {useCameraLockedData, useWidgetProps} from '../hooks';
import {Widget} from '@netvision/lib-widget-renderer';
import { INITIAL_DATA } from '../providers/block-camera-provider';

export const LockButtonAdapter = () => {
  const {mountChildren, cameraId, camera, featureLockingDisabled} = useWidgetProps();
  const camId = (camera?.id || cameraId || '').toString();
  const ref = useRef<HTMLDivElement>(null!);
  const {setter} = useCameraLockedData();

  useEffect(() => {
    if (!camId) return;
    if (featureLockingDisabled) {
      setter({...INITIAL_DATA, isLoading: false})
      return
    }

    const object = {
      type: 'widget',
      src: '@netvision/widget-react-camera-lock-button',
      props: {
        cameraIds: [camId],
        onChangeLockingInfo: setter
      }
    } as Widget;

    return mountChildren(ref.current, [object]);
  }, [camId, mountChildren]);

  return <div ref={ref} />;
};
