import {IStreamAdapter} from './IStreamAdapter';
import {EStreamType} from './EStreamType';

export const selectStreamAdapter = (type: EStreamType): Promise<IStreamAdapter> => {
  switch (type) {
    case EStreamType.SLDP:
      return import('./sldp/SldpAdapter').then((module) => module.createAdapter());
    case EStreamType.HLS:
      return import('./hls/HlsAdapter').then((module) => module.createAdapter());
  }
};
