import {
  ICameraPreset,
  ptzCreatePreset,
  updatePresetImage,
  ptzGetPresets,
  ptzRemovePreset,
  ptzSetPreset
} from '../helpers/requests';
import React, {createContext, FC, useCallback, useContext, useMemo, useRef, useState} from 'react';
import {IPtzValues} from '../helpers/calculatePtz';
import {useToastRef} from '../hooks/useToastRef';
import {useLocale} from '../../../hooks';

export interface UpdatePresetPayload {
  presetId: string
  title: string
  currentPtzStatus?: IPtzValues
  streamId?: string
}

export const usePresets = () => {
  return useContext(queryCtx);
};

export const usePresetsCommands = () => {
  return useContext(commandCtx);
};

const queryCtx = createContext<[loading: boolean, presets: ICameraPreset[], load: () => Promise<void>]>(null!);

const commandCtx = createContext<{
  updatePreset(payload: UpdatePresetPayload): Promise<void>;
  createPreset(title: string): Promise<void>;
  setPreset(presetId: string, title?: string): Promise<void>;
  removePreset(presetId: string): Promise<void>;
}>(null!);

export const PresetsProvider: FC<{cameraId: string}> = ({cameraId, children}) => {
  const [presets, setPresets] = useState<ICameraPreset[]>([]);
  const [loading, setLoading] = useState(false);

  const presetsCache = useRef<ICameraPreset[]>(null!);

  const toastRef = useToastRef();
  const locale = useLocale();

  const refreshPresets = useCallback(
    async (clearCache?: boolean) => {
      setLoading(true);
      try {
        if (presetsCache.current && !clearCache) return;
        const presets = await ptzGetPresets(cameraId);
        presetsCache.current = presets;
        setPresets(presets);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [cameraId]
  );

  const commands = useMemo(
    () => ({
      updatePreset: ({ presetId, title, currentPtzStatus, streamId }: UpdatePresetPayload) => {
        const res = updatePresetImage(presetId, title, currentPtzStatus, streamId);
        res
          .then(() => {
            toastRef.current?.show({
              severity: 'success',
              summary: locale.messages.success,
              detail: locale.messages.presetUpdated
            });
          })
          .catch(() => {
            toastRef.current?.show({
              severity: 'error',
              summary: locale.messages.error,
              detail: locale.messages.presetError
            });
          })
          .finally(() => refreshPresets(true));
        return res;
      },
      createPreset: (title: string) => {
        const res = ptzCreatePreset(cameraId, title);
        res.then(() => refreshPresets(true));
        return res;
      },
      setPreset: (presetId: string, title?: string) => {
        const res = ptzSetPreset(presetId, title);
        res.then(() => refreshPresets(true));
        return res;
      },
      removePreset: (presetId: string) => {
        const res = ptzRemovePreset(cameraId, presetId);
        res.then(() => refreshPresets(true));
        return res;
      }
    }),
    [cameraId, locale, refreshPresets, toastRef]
  );

  return (
    <commandCtx.Provider value={commands}>
      <queryCtx.Provider value={[loading, presets, refreshPresets]}>{children}</queryCtx.Provider>
    </commandCtx.Provider>
  );
};
