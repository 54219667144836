import React, {createContext, ReactNode, useMemo} from 'react';
import {repositoryGetter, TUnionRepo, TLibProp} from '@netvision/lib-api-repo';

export const Provider = ({children, lib}: TApiRepositoryProviderProps) => {
  const api = useMemo(() => repositoryGetter(lib) as TUnionRepo, []);
  const value = useMemo(() => ({api}), [api]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export type TApiRepositoryProviderProps = {
  lib?: TLibProp;
  children: ReactNode;
};

export const Context = createContext({
  api: {} as TUnionRepo
});
