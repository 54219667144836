import {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';

export const useDialogVisible = <T extends (...args: any[]) => void>(onHide: T): [boolean, T] => {
  const mountedRef = useRef(false);
  useLayoutEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(true);
  }, []);
  return [
    visible,
    useMemo(() => {
      return new Proxy(onHide, {
        apply(target: T, thisArg, argArray) {
          setVisible(false);
          setTimeout(() => {
            if (mountedRef.current) {
              Reflect.apply(target, thisArg, argArray);
            }
          }, 200);
        }
      });
    }, [onHide])
  ];
};
